import { AxiosResponse } from 'axios';
import {
  GenerateDailyWorkReceiptParams,
  ReceiptUserListItem,
  SerDailyWorkBankHourData,
} from '../@types/daily-work';
import {
  DailyWork,
  DailyWorkResponse,
  DailyWorkType,
} from '../models/daily-work';
import { UserRole } from '../models/user';
import { api } from '../providers/base-api';
import {
  AddDailyWorkJustificationRequetData,
  AddDailyWorkManuallyRequetData,
  EditDailyWorkData,
  GenerateUserMirrorRequestParams,
} from '../utils/types/data/services/daily-work';

export async function createDailyWorkRequest(): Promise<DailyWork> {
  const response = await api.post<DailyWork>('/work-histories');

  return response.data;
}

export async function updateDailyWorkRequest(
  id: number,
  data: { type: DailyWorkType },
): Promise<DailyWork> {
  const response = await api.put<DailyWork>(`/work-histories/${id}`, data);

  return response.data;
}

export async function getLastDailyWorkRequest(
  userId: number,
  params?: {
    date?: string;
  },
): Promise<DailyWork> {
  const response = await api.get<DailyWork>(`/work-histories/${userId}`, {
    params,
  });

  return response.data;
}

export async function getDailyWorksRequest(params?: {
  dateBegin: string;
  dateEnd: string;
  user?: number;
  role?: UserRole;
}): Promise<DailyWorkResponse[]> {
  const response = await api.get<DailyWorkResponse[]>(`/work-histories`, {
    params,
  });

  return response.data;
}

export async function generateDailyWorkReceiptRequest(
  query: GenerateDailyWorkReceiptParams,
): Promise<Blob> {
  const response = await api.get<Blob>(`/work-histories/receipt`, {
    params: {
      ...query,
      users: query.users.join(','),
    },
    responseType: 'blob',
  });

  return response.data;
}

export async function generateUserMirrorRequest(
  query: GenerateUserMirrorRequestParams,
): Promise<AxiosResponse<Blob>> {
  const response = await api.get<Blob>(`/work-histories/mirror/create`, {
    params: {
      employees: query.employees,
      date: query.date,
    },
    responseType: 'blob',
  });

  return response;
}

export async function getDailyWorkReceiptUserListRequest(
  query: Omit<GenerateDailyWorkReceiptParams, 'users'>,
): Promise<ReceiptUserListItem[]> {
  const response = await api.get<ReceiptUserListItem[]>(
    `/work-histories/receipt-list`,
    {
      params: query,
    },
  );

  return response.data;
}

export async function editDailyWorkRequest(
  id: number,
  data: EditDailyWorkData,
): Promise<AxiosResponse<DailyWork>> {
  const response = await api.put<DailyWork>(
    `/work-histories/change-time/${id}`,
    data,
  );

  return response;
}

export async function setDailyWorkBankHourRequest(
  data: SerDailyWorkBankHourData,
): Promise<void> {
  const response = await api.put<void>(`/work-histories/bank-hour`, data);

  return response.data;
}

export async function addDailyWorkManuallyRequest(
  data: AddDailyWorkManuallyRequetData,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(`/work-histories/manually`, data);

  return response;
}

export async function addDailyWorkJustificationRequest(
  data: AddDailyWorkJustificationRequetData,
): Promise<AxiosResponse<void>> {
  const response = await api.post<void>(`/work-histories/justification`, data);

  return response;
}
