import {
  Avatar,
  Button,
  Card,
  Container,
  Flex,
  Grid,
  Group,
  Image,
  Modal,
  Popover,
  ScrollArea,
  Text,
  Title,
} from '@mantine/core';
import {
  IconClock,
  IconClockCancel,
  IconDoorEnter,
  IconDoorExit,
} from '@tabler/icons';
import { useEffect, useState } from 'react';

import { CustomLoader } from '../../components/custom-loader';
import { Page } from '../../components/page';
import {
  createDailyWorkRequest,
  getLastDailyWorkRequest,
  updateDailyWorkRequest,
} from '../../data/daily-work';
import useAuthContext from '../../hooks/useAuthContext';
import { useDeviceDetector } from '../../hooks/useDeviceDetector';
import { useLocation } from '../../hooks/useLocation';
import { DailyWork, DailyWorkType, WorkType } from '../../models/daily-work';
import { dayjsPlugins, formatLocale } from '../../providers/dayjs-plugins';
import { errorNotification } from '../../providers/mantine-notifications';
import { allowedLocations } from '../../utils/constants/locations';
import { translateServerHttpErrors } from '../../utils/helpers';
import { getDistanceFromLatLonInKm } from '../../utils/location';

type ConfirmStateType =
  | 'confirm-begin'
  | 'confirm-start-lounch'
  | 'confirm-end-lounch'
  | 'confirm-finish'
  | null;

export function WorkHistoryCreate() {
  const [pageLoading, setPageLoading] = useState(false);
  const [pageModal, setPageModal] = useState(false);
  const [pageConfirm, setPageConfirm] = useState<ConfirmStateType>(null);
  const [dailyWork, setDailyWork] = useState<DailyWork>();
  const [currentHour, setCurrentHour] = useState<Date>(new Date());
  const { user, logout } = useAuthContext();
  const { deviceType } = useDeviceDetector();
  const { permissionStatus, location } = useLocation();

  function validateWorkInterval(historyTime?: string) {
    if (dayjsPlugins(new Date()).diff(historyTime, 'minutes') < 20) {
      errorNotification({
        title: 'Você não atingiu o tempo mínimo.',
        message: 'necessário 20 minutos de intervalo entre os registros',
      });
      throw false;
    }
  }

  async function handleGetWorkHistory() {
    try {
      setPageLoading(true);
      const response = await getLastDailyWorkRequest(Number(user?.user.id), {
        date: new Date().toISOString(),
      });
      setDailyWork(response);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
    }
  }

  async function handleSubmit(type: DailyWorkType) {
    const allowedList = allowedLocations.map((item) => {
      const distanceInKm = getDistanceFromLatLonInKm({
        lat1: location.latitude,
        lon1: location.longitude,
        lat2: item.latitude,
        lon2: item.longitude,
      });

      if (distanceInKm > 5) {
        return false;
      } else {
        return true;
      }
    });

    if (
      ![1466, 584, 811, 548, 530, 1905].includes(Number(user?.user.id)) &&
      !allowedList.includes(true)
    ) {
      errorNotification({
        title: 'Você esta fora da área permitida.',
        message: 'necessário estar na área da empresa',
      });
      return;
    }

    setPageConfirm(null);
    try {
      if (type === DailyWorkType.END_LOUNCH) {
        validateWorkInterval(dailyWork?.start_lounch);
      }

      setPageLoading(true);
      if (type === DailyWorkType.BEGIN) {
        const response = await createDailyWorkRequest();
        setDailyWork(response);
      } else {
        const response = await updateDailyWorkRequest(Number(dailyWork?.id), {
          type,
        });
        setDailyWork(response);
      }
      setPageLoading(false);
      setPageModal(true);
    } catch (error: any) {
      errorNotification({
        title: translateServerHttpErrors(error, 'Erro ao registrar horário.'),
        message: 'tente novamente',
      });
      setPageLoading(false);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => setCurrentHour(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    handleGetWorkHistory();
  }, []);

  return (
    <Page>
      <CustomLoader loading={pageLoading} />
      <Group mb={8}>
        <Avatar src={null} alt="no image here" color="indigo" />
        <Text>
          Olá,{' '}
          <Text fw="bold" span c="indigo" inherit>
            {user?.user.username}
          </Text>
        </Text>
      </Group>
      <Grid columns={3}>
        <Grid.Col span={2}>
          <Card h={'75vh'} p={16}>
            <Title size={20} mb={'xs'}>
              Registro de Horários ⏱️
            </Title>
            <Title align="center">{currentHour.toLocaleTimeString()}</Title>
            <Title size={18} align="center" mb={'xs'}>
              {dayjsPlugins().format('ddd[,] DD [de] MMMM')}
            </Title>
            <Text align="center" mb={16}>
              Clique no botão correspondente ao horário que deseja registrar.
            </Text>
            {dailyWork?.type === WorkType.JUSTIFICATION && (
              <Text align="center" mb={16} color="red" fw={'bold'} size={'md'}>
                Justificativa: {dailyWork.justification}
              </Text>
            )}
            <Flex justify="center" wrap="wrap">
              <Flex direction="column" align="center" mr={16}>
                <Popover
                  opened={pageConfirm === 'confirm-begin'}
                  width={225}
                  position="bottom"
                  withArrow
                  shadow="md"
                >
                  <Popover.Target>
                    <Button
                      disabled={
                        !!dailyWork?.begin ||
                        dailyWork?.type === WorkType.JUSTIFICATION
                      }
                      onClick={() => setPageConfirm('confirm-begin')}
                      color="green"
                      rightIcon={<IconDoorEnter size={20} />}
                    >
                      Entrada
                    </Button>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Text size="sm">
                      Deseja realmente registrar "Entrada" ?
                    </Text>
                    <Flex>
                      <Button
                        loading={pageLoading}
                        onClick={() => handleSubmit(DailyWorkType.BEGIN)}
                        mr={8}
                        color="green"
                      >
                        Sim
                      </Button>
                      <Button onClick={() => setPageConfirm(null)} color="red">
                        Não
                      </Button>
                    </Flex>
                  </Popover.Dropdown>
                </Popover>
                <Text>
                  {dailyWork?.begin
                    ? formatLocale(dailyWork?.begin, 'DD/MM/YY HH:mm')
                    : ''}
                </Text>
              </Flex>
              <Flex direction="column" align="center" mr={16}>
                <Popover
                  opened={pageConfirm === 'confirm-start-lounch'}
                  width={225}
                  position="bottom"
                  withArrow
                  shadow="md"
                >
                  <Popover.Target>
                    <Button
                      disabled={
                        !!dailyWork?.start_lounch ||
                        !dailyWork?.begin ||
                        !!dailyWork?.finish ||
                        dailyWork.type === WorkType.JUSTIFICATION
                      }
                      onClick={() => setPageConfirm('confirm-start-lounch')}
                      color="orange"
                      rightIcon={<IconClock size={20} />}
                    >
                      Inicio Intervalo
                    </Button>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Text size="sm">
                      Deseja realmente registrar "Inicio Intervalo" ?
                    </Text>
                    <Flex>
                      <Button
                        loading={pageLoading}
                        onClick={() => handleSubmit(DailyWorkType.START_LOUNCH)}
                        mr={8}
                        color="green"
                      >
                        Sim
                      </Button>
                      <Button onClick={() => setPageConfirm(null)} color="red">
                        Não
                      </Button>
                    </Flex>
                  </Popover.Dropdown>
                </Popover>
                <Text>
                  {dailyWork?.start_lounch
                    ? formatLocale(dailyWork?.start_lounch, 'DD/MM/YY HH:mm')
                    : ''}
                </Text>
              </Flex>
              <Flex direction="column" align="center" mr={16}>
                <Popover
                  opened={pageConfirm === 'confirm-end-lounch'}
                  width={225}
                  position="bottom"
                  withArrow
                  shadow="md"
                >
                  <Popover.Target>
                    <Button
                      disabled={
                        !!dailyWork?.end_lounch ||
                        !dailyWork?.begin ||
                        !!dailyWork?.finish ||
                        dailyWork.type === WorkType.JUSTIFICATION
                      }
                      onClick={() => setPageConfirm('confirm-end-lounch')}
                      color="orange.4"
                      rightIcon={<IconClockCancel size={20} />}
                    >
                      Fim Intervalo
                    </Button>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Text size="sm">
                      Deseja realmente registrar "Fim Intervalo" ?
                    </Text>
                    <Flex>
                      <Button
                        loading={pageLoading}
                        onClick={() => handleSubmit(DailyWorkType.END_LOUNCH)}
                        mr={8}
                        color="green"
                      >
                        Sim
                      </Button>
                      <Button onClick={() => setPageConfirm(null)} color="red">
                        Não
                      </Button>
                    </Flex>
                  </Popover.Dropdown>
                </Popover>
                <Text>
                  {dailyWork?.end_lounch
                    ? formatLocale(dailyWork?.end_lounch, 'DD/MM/YY HH:mm')
                    : ''}
                </Text>
              </Flex>
              <Flex direction="column" align="center" mr={16}>
                <Popover
                  opened={pageConfirm === 'confirm-finish'}
                  width={225}
                  position="bottom"
                  withArrow
                  shadow="md"
                >
                  <Popover.Target>
                    <Button
                      disabled={
                        !!dailyWork?.finish ||
                        !dailyWork?.begin ||
                        dailyWork.type === WorkType.JUSTIFICATION
                      }
                      onClick={() => setPageConfirm('confirm-finish')}
                      color="red"
                      rightIcon={<IconDoorExit size={20} />}
                    >
                      Saída
                    </Button>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Text size="sm">Deseja realmente registrar "Saída" ?</Text>
                    <Flex>
                      <Button
                        loading={pageLoading}
                        onClick={() => handleSubmit(DailyWorkType.FINISH)}
                        mr={8}
                        color="green"
                      >
                        Sim
                      </Button>
                      <Button onClick={() => setPageConfirm(null)} color="red">
                        Não
                      </Button>
                    </Flex>
                  </Popover.Dropdown>
                </Popover>
                <Text>
                  {dailyWork?.finish
                    ? formatLocale(dailyWork?.finish, 'DD/MM/YY HH:mm')
                    : ''}
                </Text>
              </Flex>
            </Flex>
          </Card>
        </Grid.Col>
        <Grid.Col span={1}>
          <Card h="75vh">
            <Title size={20}>Comunicados 📋</Title>
            <ScrollArea mt={16}>
              <Card bg="gray.2">
                <Text>sem comunicados</Text>
              </Card>
            </ScrollArea>
          </Card>
        </Grid.Col>
      </Grid>
      <Container></Container>
      <Modal
        title="Registro de Horário."
        opened={pageModal}
        onClose={() => setPageModal(false)}
        closeOnClickOutside={false}
        withCloseButton={false}
        closeOnEscape={false}
      >
        <Group>
          <Text fw="bold">Tudo certo!</Text>
          <Text>Seu horário foi registrado, você será deslogado.</Text>
        </Group>
        <Group position="right">
          <Button onClick={() => logout()}>OK</Button>
        </Group>
      </Modal>
      <Modal
        title="Acesso Negado."
        opened={deviceType === 'Mobile'}
        onClose={() => setPageModal(false)}
        closeOnClickOutside={false}
        withCloseButton={false}
        closeOnEscape={false}
      >
        <Group>
          <Text fw="bold">Opa!</Text>
          <Text>
            Você não pode utlizar este sistema a partir de um celular.
          </Text>
        </Group>
        <Group position="right">
          <Button onClick={() => logout()}>OK</Button>
        </Group>
      </Modal>
      <Modal
        size={600}
        title="Acesso a Localização."
        opened={permissionStatus === 'denied'}
        onClose={() => setPageModal(false)}
        closeOnClickOutside={false}
        withCloseButton={false}
        closeOnEscape={false}
      >
        <Group mb={16}>
          <Text fw="bold">Localização necessária!</Text>
          <Text>
            Você não pode registrar o ponto sem dar acesso a localização veja
            como fazer:
          </Text>
        </Group>
        <Image
          mb={16}
          src="https://orders-files.nyc3.cdn.digitaloceanspaces.com/media/user-activate-location.gif"
        />
        <Group>
          <Image />
        </Group>
      </Modal>
    </Page>
  );
}
