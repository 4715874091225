import { useFetch } from '../../../hooks/useFetch';
import { CashFlow } from '../../../models/cash-flow';
import { errorNotification } from '../../../providers/mantine-notifications';
import { translateServerHttpErrors } from '../../../utils/helpers';
import {
  UseCancelCashFlowProps,
  UseCashFlowRechargeProps,
  UseCashFlowWithdrawalProps,
  UseGenerateGenericCashFlowReceipt,
  UseGetCashFlowBalanceProps,
  UseGetCashFlowTransactionsProps,
  UsePrintCashFlowProps,
  UseSetCashFlowRoundedValueProps,
} from '../../../utils/types/data/hooks/clash-flow';
import { PaginatedResponse } from '../../../utils/types/data/pagination';
import { GetCashFlowBalanceResponse } from '../../../utils/types/data/services/cash-flow';
import {
  cancelCashFlow,
  cashFlowRechargeRequest,
  cashFlowWithdrawalRequest,
  generateGenericCashFlowReceiptRequest,
  getCashFlowBalanceRequest,
  getCashFlowTransactionsRequest,
  printCashFlowReceiptRequest,
  setCashFlowRoundedValueRequest,
} from '../../services/cash-flow';

export function useCashFlowRecharge() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<CashFlow>();

  const fetch = ({ data, onSuccess }: UseCashFlowRechargeProps) =>
    fetchData({
      fetcher: () => cashFlowRechargeRequest(data),
      onError: () => {
        errorNotification({
          title: 'Erro ao adicionar valor ao caixa.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useCashFlowWithdrawal() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<CashFlow>();

  const fetch = ({ data, onSuccess }: UseCashFlowWithdrawalProps) =>
    fetchData({
      fetcher: () => cashFlowWithdrawalRequest(data),
      onError: (error) => {
        errorNotification({
          title: translateServerHttpErrors(
            error as any,
            'Erro ao dar sáida de valor ao caixa.',
          ),
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetCashFlowBalance() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<GetCashFlowBalanceResponse>();

  const fetch = ({ onSuccess }: UseGetCashFlowBalanceProps) =>
    fetchData({
      fetcher: () => getCashFlowBalanceRequest(),
      onError: () => {
        errorNotification({
          title: 'Erro ao atualizar caixa.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetCashFlowTransactions() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaginatedResponse<CashFlow>>();

  const fetch = ({ onSuccess, params }: UseGetCashFlowTransactionsProps) =>
    fetchData({
      fetcher: () => getCashFlowTransactionsRequest(params),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar movimentação.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGenerateGenericCashFlowReceipt() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<Blob>();

  const fetch = ({ onSuccess, data }: UseGenerateGenericCashFlowReceipt) =>
    fetchData({
      fetcher: () => generateGenericCashFlowReceiptRequest(data),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar movimentação.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function usePrintCashFlowReceipt() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<Blob>();

  const fetch = ({ onSuccess, id }: UsePrintCashFlowProps) =>
    fetchData({
      fetcher: () => printCashFlowReceiptRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar movimentação.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useSetCashFlowRoundedValue() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<CashFlow>();

  const fetch = ({ onSuccess, data, id }: UseSetCashFlowRoundedValueProps) =>
    fetchData({
      fetcher: () => setCashFlowRoundedValueRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao setar valor.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useCancelCashFlow() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<CashFlow>();

  const fetch = ({ onSuccess, id }: UseCancelCashFlowProps) =>
    fetchData({
      fetcher: () => cancelCashFlow(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao cancelar movimentação.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
