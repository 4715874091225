import { useFetch } from '../../../hooks/useFetch';
import { BankHour } from '../../../models/bank-hour';
import { errorNotification } from '../../../providers/mantine-notifications';
import {
  UseDeleteBankHoursProps,
  UseGetBankHoursProps,
} from '../../../utils/types/data/hooks/bank-hour';
import { PaginatedResponse } from '../../../utils/types/data/pagination';
import { getBankHoursRequest, removeBankHourRequest } from '../../bank-hours';

export function useGetBankHours() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaginatedResponse<BankHour>>();

  const fetch = ({ onSuccess, params }: UseGetBankHoursProps) =>
    fetchData({
      fetcher: () => getBankHoursRequest(params),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar banco de horas.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useDeleteBankHour() {
  const {
    fetchData,
    state: { error, loading },
  } = useFetch<void>();

  const fetch = ({ onSuccess, params }: UseDeleteBankHoursProps) =>
    fetchData({
      fetcher: () => removeBankHourRequest(params.id),
      onError: () => {
        errorNotification({
          title: 'Erro ao remover banco de horas.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    error,
    loading,
  };
}
