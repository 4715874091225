import { User } from './user';

export enum DailyWorkType {
  BEGIN = 'begin',
  START_LOUNCH = 'start_lounch',
  END_LOUNCH = 'end_lounch',
  FINISH = 'finish',
}

export enum WorkType {
  WORK = 'work',
  JUSTIFICATION = 'justification',
}

export type DailyWork = {
  id: number;
  user: User;
  begin?: string;
  start_lounch?: string;
  end_lounch?: string;
  finish?: string;
  price?: string;
  isPaid: boolean;
  isBankHour: boolean;
  description?: string;
  createdBy?: string;
  deletedAt?: string;
  deletedBy?: string;
  type: WorkType;
  justification?: string;
  isCompensation: boolean;
};

export type DailyWorkResponse = Omit<DailyWork, 'user'> & {
  username: string;
  totalValue: string;
  workHours: string;
};
