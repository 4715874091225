export enum AppRoutePaths {
  LOGIN = '/',
  BIOMETRIC_LOGIN = '/biometric',
  HOME = '/home',
  USERS = '/users',
  USERS_CREATE = '/users/create',
  USERS_EDIT = '/users/edit/:id',
  COMPANIES = '/companies',
  COMPANIES_CREATE = '/companies/create',
  COMPANIES_EDIT = '/companies/edit/:id',
  WORK_HISTORY = '/work-histories',
  WORK_HISTORY_CREATE = '/work-histories/create',
  CASH_FLOW = '/cash-flow',
  EMPLOYEE_TRANSPORT = '/employee-transport',
  NOT_FOUND = '*',
  BANK_HOURS = '/bank-hours',
}
