import { useEffect, useState } from 'react';
import { rootNavigate } from '../components/custom-router';
import { loginByBiometric, loginRequest } from '../data/auth';
import { identificationBiometric } from '../data/biometric';
import { UserRole, UserType } from '../models/user';
import { errorNotification } from '../providers/mantine-notifications';
import { LocalStorageKeys } from '../utils/enums/local-storage';
import { AppRoutePaths } from '../utils/enums/routes';
import { cryptData } from '../utils/helpers';
import { useLocalStorage } from './useLocalStorage';
import { useUser } from './useUser';

export const useAuth = () => {
  const { user, addUser, removeUser } = useUser();
  const [loading, setLoading] = useState(false);
  const [biometricAttempt, setBiometricAttempt] = useState<number>(0);
  const { getItem } = useLocalStorage();

  useEffect(() => {
    const userJSON = getItem(LocalStorageKeys.LOGGED_USER);
    if (userJSON) {
      addUser(JSON.parse(userJSON));
    }
  }, []);

  const login = async (data: { accessCode: string; password: string }) => {
    try {
      setLoading(true);
      const response = await loginRequest(data);
      addUser({
        user: response.user,
        authToken: response.access_token,
      });
      if (
        response.user.role === UserRole.EMPLOYEE ||
        response.user.role === UserRole.DAILY_EMPLOYEE ||
        response.user.role === UserRole.MONTHLY_EMPLOYEE
      ) {
        rootNavigate(AppRoutePaths.WORK_HISTORY_CREATE);
      }
      if (
        response.user.type === UserType.MASTER ||
        response.user.type === UserType.MANAGER
      ) {
        rootNavigate(AppRoutePaths.HOME);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorNotification({
        title: 'Erro ao fazer login',
        message: 'revize os dados e tente novamente.',
      });
    }
  };

  const logout = () => {
    removeUser();
    if (
      window.location.pathname !== AppRoutePaths.LOGIN &&
      window.location.pathname !== AppRoutePaths.BIOMETRIC_LOGIN
    ) {
      rootNavigate(AppRoutePaths.LOGIN);
    }
  };

  const biometricLogin = async () => {
    setLoading(true);
    await identificationBiometric()
      .then(async (resp) => {
        if (resp.data.success) {
          setBiometricAttempt(0);
          const { cryptValidator, cryptTemplate } = cryptData({
            validator: resp.data.id,
            template: resp.data.template,
          });
          const response = await loginByBiometric({
            validator: cryptValidator,
            template: cryptTemplate,
          });
          addUser({
            user: response.user,
            authToken: response.access_token,
          });

          if (
            response.user.role === UserRole.EMPLOYEE ||
            response.user.role === UserRole.DAILY_EMPLOYEE ||
            response.user.role === UserRole.MONTHLY_EMPLOYEE
          ) {
            rootNavigate(AppRoutePaths.WORK_HISTORY_CREATE);
          }
          if (
            response.user.type === UserType.MASTER ||
            response.user.type === UserType.MANAGER
          ) {
            rootNavigate(AppRoutePaths.HOME);
          }
          setLoading(false);
        } else if (!resp.data.success) {
          setBiometricAttempt(biometricAttempt + 1);
          setLoading(false);
          errorNotification({
            title: 'Erro ao fazer login',
            message: 'Usuário ou Biometria não encontrados!',
          });
        }
      })
      .catch(() => {
        setLoading(false);
        errorNotification({
          title: 'Falha ao realizar login',
          message: 'Dispositivo não reconhecido!',
        });
      });
  };

  return {
    user,
    login,
    logout,
    loading,
    biometricLogin,
    biometricAttempt,
    setBiometricAttempt,
  };
};
