import { useEffect, useState } from 'react';

export const useLocation = () => {
  const [permissionStatus, setPermission] = useState<
    'granted' | 'prompt' | 'denied' | null
  >(null);
  const [location, setLocation] = useState({
    latitude: 0,
    longitude: 0,
  });

  function handlePermission() {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      setPermission(result.state);
      if (result.state === 'prompt' || result.state === 'granted') {
        navigator.geolocation.getCurrentPosition((pos) => {
          setLocation({
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          });
        });
      }
    });
  }

  useEffect(() => {
    handlePermission();
  }, []);

  return {
    permissionStatus,
    location,
  };
};
