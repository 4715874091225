import { AxiosError } from 'axios';
import { formatLocale } from '../providers/dayjs-plugins';
import { errorsTranslate } from './constants/errors';

export function formatDateTime(date: Date | string) {
  return formatLocale(date, 'DD/MM/YY HH:mm');
}

export function formatBRL(value: string | null | undefined) {
  let operator = '';

  if (!value) {
    return '';
  }

  operator = value.match(/[-]/g) ? '-' : '';

  value = value.replace(/\D/g, '');
  value = (Number(value) / 100).toFixed(2) + '';
  value = value.replace('.', ',');
  value = value.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
  value = value.replace(/(\d)(\d{3}),/g, '$1.$2,');

  return `${operator} R$${value}`;
}

export function realToUSCash(value: string) {
  if (!value) {
    return '';
  }

  return value
    .replace('R$', '')
    .replace('.', '')
    .replace(',', '.')
    .replace(' ', '');
}

export function translateServerHttpErrors(
  err: AxiosError<{ message: string }>,
  defaultMessage: string,
) {
  if (err.response?.data.message) {
    return errorsTranslate?.[err.response?.data.message];
  } else {
    return defaultMessage;
  }
}

export function getMinutesAndHours(minutes: number) {
  const restMinutes = minutes % 60;
  const hours = (minutes - restMinutes) / 60;

  return {
    hours,
    restMinutes,
  };
}

export function cryptData(data: { validator: string; template: string }) {
  return {
    cryptValidator: btoa(`${data.validator}/${new Date().getTime()}`),
    cryptTemplate: btoa(data.template),
  };
}
