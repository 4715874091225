type ComparableItem = {
  lat1: number;
  lon1: number;
  lat2: number;
  lon2: number;
};

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

export function getDistanceFromLatLonInKm({
  lat1,
  lon1,
  lat2,
  lon2,
}: ComparableItem) {
  const RADIUS = 6371; // Radius of the earth in km
  const degreeLat = deg2rad(lat2 - lat1); // deg2rad below
  const degreeLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(degreeLat / 2) * Math.sin(degreeLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(degreeLon / 2) *
      Math.sin(degreeLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const kmDistance = RADIUS * c; // Distance in km

  return Number(kmDistance.toFixed(2));
}
