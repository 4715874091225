import { WorkType } from '../../models/daily-work';

export const workJustification = [
  {
    value: 'Atestado',
    label: 'Atestado',
  },
  {
    value: 'Banco de horas',
    label: 'Banco de horas',
  },
  {
    value: 'Licença nojo (atestado de óbito)',
    label: 'Licença nojo (atestado de óbito)',
  },
  {
    value: 'Licença gala (casamento)',
    label: 'Licença gala (casamento)',
  },
  {
    value: 'Licença maternidade',
    label: 'Licença maternidade',
  },
  {
    value: 'Licença paternidade',
    label: 'Licença paternidade',
  },
  {
    value: 'Licença amamentação',
    label: 'Licença amamentação',
  },
  {
    value: 'INSS',
    label: 'INSS',
  },
  {
    value: 'Declaração de Comparecimento',
    label: 'Declaração de Comparecimento',
  },
  {
    value: 'Declaração de Acompanhante',
    label: 'Declaração de Acompanhante',
  },
  {
    value: 'Férias',
    label: 'Férias',
  },
];

export const dailyWorkTypeHumanized = {
  [WorkType.JUSTIFICATION]: 'justificativa',
  [WorkType.WORK]: 'horário',
};
