import { useFetch } from '../../../hooks/useFetch';
import { EmployeeTransport } from '../../../models/employee-transport';
import { errorNotification } from '../../../providers/mantine-notifications';
import { UseGetUserEmployeeTransportProps } from '../../../utils/types/data/hooks/employee-transport';
import { PaginatedResponse } from '../../../utils/types/data/pagination';
import {
  getEmployeeTransportListRequest,
  inactivateEmployeeTransportRequest,
} from '../../employee-transport';

export function useGetUserEmployeeTransport() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaginatedResponse<EmployeeTransport>>();

  const fetch = ({ data, onSuccess }: UseGetUserEmployeeTransportProps) =>
    fetchData({
      fetcher: () => getEmployeeTransportListRequest(data),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar lista de Vale Transporte.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useInactivateEmployeeTransport() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ data }: { data: { id: number } }) =>
    fetchData({
      fetcher: () => inactivateEmployeeTransportRequest(data),
      onError: () => {
        errorNotification({
          title: 'Erro ao inativar VT.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
