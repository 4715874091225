import { useFetch } from '../../../hooks/useFetch';
import { User } from '../../../models/user';
import { errorNotification } from '../../../providers/mantine-notifications';
import {
  UseGetUsersPaginatedProps,
  UseRestoreUserProps,
} from '../../../utils/types/data/hooks/users';
import { PaginatedResponse } from '../../../utils/types/data/pagination';
import { getUsersRequest, restoreUserRequest } from '../../services/user';
import { geteUsersPaginatedRequest } from '../../user';

export function useGetUser() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<User[]>();

  const fetch = () =>
    fetchData({
      fetcher: () => getUsersRequest(),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar usuários.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useRestoreUser() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ onSuccess, param }: UseRestoreUserProps) =>
    fetchData({
      fetcher: () => restoreUserRequest(param),
      onError: () => {
        errorNotification({
          title: 'Erro ao reativar usuário.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetUsersPaginated() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaginatedResponse<User>>();

  const fetch = ({ query }: UseGetUsersPaginatedProps) =>
    fetchData({
      fetcher: () => geteUsersPaginatedRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar usuários.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
