export const allowedLocations = [
  {
    name: 'LTP',
    latitude: -23.53344924921062,
    longitude: -46.628104505148144,
    address: 'R. João Teodoro, 458 - Luz, São Paulo - SP, 01105-000',
  },
  {
    name: 'Laser & Tinta',
    latitude: -23.53390641100271,
    longitude: -46.62983995827403,
    address: 'Rua Guilherme Maw, 113 - Luz, São Paulo - SP, 01105-040',
  },
  {
    name: '25 Gravações',
    latitude: -23.53423257851374,
    longitude: -46.62880218473418,
    address: 'R. Djalma Dutra, 192 - Luz, São Paulo - SP, 01103-010',
  },
  {
    name: 'Central Gravações',
    latitude: -23.537703462094953,
    longitude: -46.63056576881484,
    address: 'R. Antônio Pais, 65 - Luz, São Paulo - SP, 01027-020',
  },
  {
    name: 'Acrilicos Color',
    latitude: -23.534120744042365,
    longitude: -46.625936413180675,
    address: 'R. João Teodoro, 674 - Luz, São Paulo - SP, 01105-000',
  },
  {
    name: 'LTP Express',
    latitude: -23.534391582925267,
    longitude: -46.627727290721076,
    address: 'R. São Lázaro, 254 - Luz, São Paulo - SP, 01103-020',
  },
  {
    name: 'Oficina Criação',
    latitude: -23.53686993246993,
    longitude: -46.63024133868722,
    address: 'R. Antônio Pais, 125 - Sala 2 - Luz, São Paulo - SP, 01027-020',
  },
  {
    name: 'Personalize1',
    latitude: -23.580191749983214,
    longitude: -46.680160147210664,
    address:
      'Av. Nove de Julho, 5750 - Jardim Paulista, São Paulo - SP, 01406-200',
  },
];
